import {HttpService} from "@/services/HttpService";
import TokenService from "@/services/TokenService";


export class AuthService extends HttpService {
    public login(email: string, password: string, staySignedIn: boolean) {
        const url: string = 'auth/login';
        const data: any = {
            email,
            password
        }
        return this.post(url, data).then(response => {
            if (response.data.accessToken) {
                TokenService.setUser(response.data, staySignedIn);
            }
            return response.data;
        })
    }

    public logout() {
        TokenService.removeUser();
    }
}