export enum Platform {
    STOCK_X = 'stockx',
    FLIGHT_CLUB = 'flightclub',
    STADIUM_GOODS = 'stadiumgoods',
    SPORTING_LIFE = 'sportinglife',
    NIKE = 'nike',
    NIKE_HK = 'nikehk',
    SOLE_SENSE = 'solesense',
    GOAT = 'goat',
    ZOZO = 'zozo',
}