<template>
    <div id="app">
        <div class="sidebar-page">
            <b-navbar :shadow="true" class="is-primary" v-if="isLoggedIn">
                <template #brand>
                    <b-navbar-item tag="router-link" :to="{ path: '/' }">
                        {{ applicationName }}
                    </b-navbar-item>
                </template>

                <template #start>
                    <b-navbar-dropdown label="Menu" class="is-hidden-desktop">
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'active-sneaker' }"
                                       :active="isActive('active-sneaker')">
                            Active Sneaker
                        </b-navbar-item>
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'nike-hk' }"
                                       :active="isActive('nike-hk')">
                            Nike Hong Kong
                        </b-navbar-item>
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'nike' }"
                                       :active="isActive('nike')">
                            Nike
                        </b-navbar-item>

                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'nike-report' }"
                                       :active="isActive('nike-report')">
                            Nike (New)
                        </b-navbar-item>

                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'sole-sense' }"
                                       :active="isActive('sole-sense')">
                            SoleSense
                        </b-navbar-item>
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'sporting-life' }"
                                       :active="isActive('sporting-life')">
                            Sporting Life
                        </b-navbar-item>
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'zozo' }"
                                       :active="isActive('zozo')">
                            Zozo Town
                        </b-navbar-item>
                    </b-navbar-dropdown>
                    <b-navbar-dropdown label="GOAT Tools" class="is-hidden-desktop">
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'goat-offer-updater' }"
                                       :active="isActive('goat-offer-updater')">
                            GOAT Offer Updater
                        </b-navbar-item>
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'goat-sales-report' }"
                                       :active="isActive('goat-sales-report')">
                            GOAT Sales Report
                        </b-navbar-item>
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'goat-price-updater' }"
                                       :active="isActive('goat-price-updater')">
                            GOAT Price Updater
                        </b-navbar-item>
                    </b-navbar-dropdown>
                    <b-navbar-dropdown label="Tools" class="is-hidden-desktop">
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'inventory-checker' }"
                                       :active="isActive('inventory-checker')">
                            Inventory Checker
                        </b-navbar-item>
                        <b-navbar-item class="is-hidden-desktop" tag="router-link"
                                       :to="{ name: 'stadium-goods-price-updater' }"
                                       :active="isActive('stadium-goods-price-updater')">
                            SG Price Updater
                        </b-navbar-item>
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'process-checker' }"
                                       :active="isActive('process-checker')">
                            Process Info
                        </b-navbar-item>
                        <b-navbar-item class="is-hidden-desktop" tag="router-link" :to="{ name: 'parameters' }"
                                       :active="isActive('parameters')">
                            Manage Parameters
                        </b-navbar-item>
                    </b-navbar-dropdown>
                </template>
                <template #end>
                    <b-navbar-item href="#" v-on:click.prevent="logOut()">
                        Log Out
                    </b-navbar-item>
                </template>
            </b-navbar>
            <section v-if="!isLoggedIn" class="section">
                <div class="container p-1">
                    <router-view/>
                </div>
            </section>
            <section v-else class="main-content columns mt-0" style="height: calc(100% - 52px)">
                <b-sidebar
                    class="is-hidden-touch"
                    position="static"
                    mobile="hide"
                    :fullheight="true"
                    type="is-light"
                    open
                >

                    <b-menu class="p-1">
                        <b-menu-list label="Menu">

                            <b-menu-item icon="chart-box" label="Active Sneaker" tag="router-link"
                                         :to="{ name: 'active-sneaker' }"
                                         :active="isActive('active-sneaker')"/>
                            <b-menu-item icon="chart-box" label="Nike Hong Kong" tag="router-link"
                                         :to="{ name: 'nike-hk' }"
                                         :active="isActive('nike-hk')"/>
                            <b-menu-item icon="chart-box" label="Nike" tag="router-link" :to="{ name: 'nike' }"
                                         :active="isActive('nike')"/>
                            <b-menu-item icon="chart-box" label="Nike (New)" tag="router-link"
                                         :to="{ name: 'nike-report' }"
                                         :active="isActive('nike-report')"/>

                            <b-menu-item icon="chart-box" label="SoleSense" tag="router-link"
                                         :to="{ name: 'sole-sense' }"
                                         :active="isActive('sole-sense')"/>

                            <b-menu-item icon="chart-box" label="Sporting Life" tag="router-link"
                                         :to="{ name: 'sporting-life' }"
                                         :active="isActive('sporting-life')"/>


                            <b-menu-item icon="chart-box" label="Zozo Town" tag="router-link" :to="{ name: 'zozo' }"
                                         :active="isActive('zozo')"/>
                        </b-menu-list>
                        <b-menu-list label="GOAT Tools">
                            <b-menu-item icon="toolbox" label="GOAT Offer Updater" tag="router-link"
                                         :to="{ name: 'goat-offer-updater' }"
                                         :active="isActive('goat-offer-updater')"/>
                            <b-menu-item icon="toolbox" label="GOAT Sales Report" tag="router-link"
                                         :to="{ name: 'goat-sales-report' }"
                                         :active="isActive('goat-sales-report')"/>
                            <b-menu-item icon="toolbox" label="GOAT Price Updater" tag="router-link"
                                         :to="{ name: 'goat-price-updater' }"
                                         :active="isActive('goat-price-updater')"/>
                        </b-menu-list>
                        <b-menu-list label="Tools">
                            <b-menu-item icon="toolbox" label="Inventory Checker" tag="router-link"
                                         :to="{ name: 'inventory-checker' }"
                                         :active="isActive('inventory-checker')"/>
                            <b-menu-item icon="toolbox" label="SG Price Updater" tag="router-link"
                                         :to="{ name: 'stadium-goods-price-updater' }"
                                         :active="isActive('stadium-goods-price-updater')"/>
                            <b-menu-item icon="toolbox" label="Process Info" tag="router-link"
                                         :to="{ name: 'process-checker' }"
                                         :active="isActive('process-checker')"/>
                            <b-menu-item icon="toolbox" label="Manage Parameters" tag="router-link"
                                         :to="{ name: 'parameters' }"
                                         :active="isActive('parameters')"/>
                        </b-menu-list>
                    </b-menu>

                </b-sidebar>

                <div class="container column is-10">
                    <div class="section">
                        <router-view/>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import BaseMixins from "@/mixins/BaseMixins";
import {mapActions, mapGetters} from 'vuex'

export default {
    name: "app",
    mixins: [BaseMixins],
    computed: {
        ...mapGetters('Auth', [
            "isLoggedIn"
        ])
    },
    methods: {
        ...mapActions("Auth", ['signOut']),
        isActive(routeName) {
            if (this.$router.currentRoute.name) {
                //console.log(this.$router.currentRoute.name,routeName)
                return this.$router.currentRoute.name.endsWith(routeName);
            }
            return false;
        },
        logOut() {
            this.signOut();
            this.$router.push("/login");
        }
    }

}
</script>
<style lang="sass">
// Import Bulma's core
@import "~@mdi/font/scss/materialdesignicons.scss"
@import "~bulma/sass/utilities/initial-variables"
@import "~bulma/sass/utilities/functions"
@import url('https://fonts.googleapis.com/css?family=Exo+2:300,400,600')
$family-primary: "Exo 2", sans-serif
$primary: #3D4A73
$info: #F26938
$radius: 0
$radius-large: 0
// Set your colors
// Import Bulma and Buefy styles
@import "~bulma/sass/utilities/derived-variables"
$navbar-box-shadow-size: 0 3px 0 0
$navbar-box-shadow-color: darken($info, 0.2)
$background: $white-ter
$menu-item-active-background-color: $info


@import "~bulma"
@import "../../node_modules/buefy/src/scss/buefy"
.p-1
    padding: 1em !important

body
    min-height: 100%
    background: $white-ter

html, body, #app, .sidebar-page
    height: 100%

.table
    td
        vertical-align: middle !important


@import "../../node_modules/bulma-multiselect/sass/multiselect"

</style>