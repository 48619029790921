import {Brand} from "@/common/Brand";

export class BrandInfo {
    private readonly _brand: Brand;
    private readonly _name: string = "";

    constructor(brand: Brand, name: string) {
        this._brand = brand;
        this._name = name;
    }


    get brand(): Brand {
        return this._brand;
    }

    get name(): string {
        return this._name;
    }
}

const brandInfos = [new BrandInfo(Brand.NIKE, 'Nike'),
    new BrandInfo(Brand.JORDAN, 'Air Jordan'),
    new BrandInfo(Brand.ADIDAS, 'Adidas'),
    new BrandInfo(Brand.OTHERS, 'Others'),
    new BrandInfo(Brand.UNKNOWN, 'Unknown')
]


export function getAllBrands(): BrandInfo[] {
    return brandInfos;
}

export function getBrandInfo(brand: Brand): BrandInfo | null {
    let infos: BrandInfo[] = brandInfos.filter((brandInfo => brandInfo.brand == brand));
    return infos.length > 0 ? infos[0] : null;
}