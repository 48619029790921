export enum Country {
    US = 'US',
    HK = 'HK',
    GB = 'GB',
    CA = 'CA',
    AU = 'AU',
    CN = 'CN',
    ID = 'ID',
    JP = 'JP',
    NZ = 'NZ',
    PH = 'PH',
    KR = 'KR',
    SG = 'SG',
    IN = 'in',
    MY = 'MY',
    TW = 'TW',
    TH = 'TH',
    VN = 'VN',
    EG = 'EG',
    MA = 'MA',
    ZA = 'ZA',
    AR = 'AR',
    CL = 'CL',
    MX = 'MX',
    PR = 'PR',
    UY = 'UY',
    XL = 'XL',
    AT = 'AT',
    BE = 'BE',
    BG = 'BG',
    HR = 'HR',
    Cz = 'CZ',
    DK = 'DK',
    FI = 'FI',
    FR = 'FR',
    DE = 'DE',
    GR = 'GR',
    HU = 'HU',
    IE = 'IE',
    IL = 'IL',
    IT = 'IT',
    LU = 'LU',
    NL = 'NL',
    NO = 'NO',
    PL = 'PL',
    PT = 'PT',
    RO = 'RO',
    RU = 'RU',
    SK = 'SK',
    SI = 'SI',
    ES = 'ES',
    SE = 'SE',
    CH = 'CH',
    TR = 'TR',
    SA = 'SA',
    AE = 'AE',
}

export function getLocaleLanguage(country: Country, locale: String) {
    switch (country) {
        case Country.FR:
            if (locale == 'fr') {
                return 'fr_FR'
            }
            break;
        case Country.JP:
            if (locale == '') {
                return "ja_JP";
            }
            break;
        case Country.TW:
            if (locale == '') {
                return 'zh_TW';
            }
            break;
        case Country.KR:
            if (locale == '') {
                return 'ko_KR';
            }
            break;
        case Country.CN:
            if (locale == '') {
                return 'zh_CN';
            }
            break;
    }

    return 'en_US';

}

export function getCountryByString(countryName: string) {
    let country: Country = (<any>Country)[countryName.toUpperCase()];
    if (country == undefined) {
        country = Country.US;
    }
    return country;
}
