import {
    digits,
    email,
    integer,
    length,
    max,
    max_value,
    min,
    min_value,
    numeric,
    required,
    required_if,
    double
} from "vee-validate/dist/rules";
import {extend} from "vee-validate";

extend("required", {
    ...required,
    //message: "This field is required"
    message: (fieldName, placeholders) => {
        return fieldName + " is required"
    }
});

extend("integer", {
    ...integer,
    message: (fieldName, placeholders) => {
        return fieldName + " must be a valid integer"
    }
});

extend("max", {
    ...max,
    message: "The max value"
});

extend("min", {
    ...min,
    message: "The min value"
});

extend("numeric", {
    ...numeric,
    message: "This field must be a valid number"
});

extend("max_value", {
    ...max_value,
    message: (fieldName, placeholders) => {
        return fieldName + " value must be smaller or equal to " + placeholders.max
    }
});
extend("min_value", {
    ...min_value,
    message: (fieldName, placeholders) => {
        return fieldName + " value must be larger or equal to " + placeholders.min
    }
});
extend("digits", {
    ...digits,
    message: "This field must be a valid number"
});

extend("email", {
    ...email,
    message: "This field must be a valid email"
});

extend("required_if", {
    ...required_if,
    message: "This field is required"
});

extend("length", {
    ...length,
    message: "This field must have 2 options"
});
extend("double", {
    ...double,
    message: "This field must be a valid number"
});
