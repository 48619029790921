import {Platform} from "@/common/Platform";

export class PlatformInfo {
    private readonly _platform: Platform;
    private readonly _name: string = "";

    constructor(platform: Platform, name: string) {
        this._platform = platform;
        this._name = name;
    }


    get platform(): Platform {
        return this._platform;
    }

    get name(): string {
        return this._name;
    }
}

const platformInfos = [new PlatformInfo(Platform.FLIGHT_CLUB, 'Flight Club'),
    new PlatformInfo(Platform.GOAT, 'GOAT'),
    new PlatformInfo(Platform.NIKE, 'Nike'),
    new PlatformInfo(Platform.NIKE_HK, 'Nike HK'),
    new PlatformInfo(Platform.SOLE_SENSE, 'SoleSense'),
    new PlatformInfo(Platform.STADIUM_GOODS, 'Stadium Goods'),
    new PlatformInfo(Platform.SPORTING_LIFE, 'Sporting Life'),
    new PlatformInfo(Platform.STOCK_X, 'StockX'),
    new PlatformInfo(Platform.ZOZO, 'Zozo Town')]

export function getAllPlatforms(): PlatformInfo[] {
    return platformInfos;
}

export function getPlatformInfo(platform: Platform): PlatformInfo | null {
    let infos: PlatformInfo[] = platformInfos.filter((platformInfo => platformInfo.platform == platform));
    return infos.length > 0 ? infos[0] : null;
}