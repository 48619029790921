import {ParameterType} from "@/common/ParameterType";

export class ParameterTypeInfo {
    private readonly _type: ParameterType;
    private readonly _name: string;


    constructor(type: ParameterType, name: string) {
        this._type = type;
        this._name = name;
    }


    get type(): ParameterType {
        return this._type;
    }

    get name(): string {
        return this._name;
    }
}


const parameterTypeInfos = [
    new ParameterTypeInfo(ParameterType.FIXED_AMOUNT, 'Fixed Amount'),
    new ParameterTypeInfo(ParameterType.PERCENTAGE, 'Percentage'),
    new ParameterTypeInfo(ParameterType.VALUE, 'Value'),
]

export function getAllParameterTypes(): ParameterTypeInfo[] {
    return parameterTypeInfos;
}

export function getParameterTypeInfo(parameterType: ParameterType): ParameterTypeInfo | null {
    let infos: ParameterTypeInfo[] = parameterTypeInfos.filter((parameterTypeInfo => parameterTypeInfo.type == parameterType));
    return infos.length > 0 ? infos[0] : null;
}