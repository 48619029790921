import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {AuthService} from '@/services/AuthService';
import TokenService from "@/services/TokenService";

@Module({namespaced: true})
export class Auth extends VuexModule {
    public status = TokenService.getUser() ? {loggedIn: true} : {loggedIn: false};
    public user = TokenService.getUser()


    @Mutation
    public loginSuccess(user: any): void {
        this.status.loggedIn = true;
        this.user = user;
    }

    @Mutation
    public loginFailure(): void {
        this.status.loggedIn = false;
        this.user = null;
    }

    @Mutation
    public logout(): void {
        this.status.loggedIn = false;
        this.user = null;
    }

    @Mutation
    public registerSuccess(): void {
        this.status.loggedIn = false;
    }

    @Mutation
    public registerFailure(): void {
        this.status.loggedIn = false;
    }

    @Action({rawError: true})
    login(data: any): Promise<any> {
        let service: AuthService = new AuthService();
        return service.login(data.email, data.password, data.staySignedIn).then(
            user => {
                this.context.commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                this.context.commit('loginFailure');
                /*const message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();*/
                return Promise.reject(error);
            }
        );
    }

    @Action({rawError: true})
    refreshToken(data: any): void {
        console.log("refreshToken",data);
        this.context.commit('loginSuccess', data);
    }

    @Action
    signOut(): void {
        let service: AuthService = new AuthService();
        service.logout();
        this.context.commit('logout');
    }

    get isLoggedIn(): boolean {
        return this.status.loggedIn;
    }
}