<template>
    <div/>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'home',
    computed: {
        ...mapGetters('Auth', [
            "isLoggedIn"
        ])
    },
    mounted() {
        if (this.isLoggedIn) {
            this.$router.push({name: 'dashboard'});
        } else {
            this.$router.push({name: 'login'});
        }
    },
}
</script>
