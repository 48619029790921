import Vue from 'vue'
import "./vee-validate"
import Buefy from 'buefy'
import App from './components/App.vue'
import router from './router'
import store from './store'
import { DatePicker } from 'element-ui';
// @ts-ignore
import lang from 'element-ui/lib/locale/lang/en'

// @ts-ignore
import locale from 'element-ui/lib/locale'
import './sass/element-variables.scss';

Vue.config.productionTip = false
import setupInterceptors from './services/SetUpInterceptor';
setupInterceptors(store);
locale.use(lang)
Vue.use(Buefy)
Vue.component(DatePicker.name, DatePicker);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
