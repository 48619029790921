import * as moment from "moment";
import {getParameterTypeInfo} from "@/common/ParameterTypeInfo";
import {getCountryInfo} from "@/common/CountryInfo";
import {getPlatformInfo} from "@/common/PlatformInfo";
import {getBrandInfo} from "@/common/BrandInfo";

const BaseMixins = {
    filters: {
        formatDate(d, format = 'MMM D, YYYY HH:mm') {
            if (moment.isMoment(d)) {
                d = moment.utc(d);
            } else {
                d = moment.utc(d); //Invalid Date
            }
            return d.isValid() ? d.local().format(format) : 'N/A';
        },
        formatPriceCents(d) {
            return "$" + parseFloat(d / 100).toFixed(2);
        },
        trimString(value, size) {
            if (value.length > size) {
                return value.substr(0, size) + '...';
            } else {
                return value;
            }
        },
        displayParameterType(value) {
            let t = getParameterTypeInfo(value);
            return t ? t.name : value;
        },
        displayCountry(country) {
            let c = getCountryInfo(country);
            return c ? c.name : country;
        },
        displayPlatform(platform) {
            let p = getPlatformInfo(platform);
            return p ? p.name : platform;
        },
        displayBrand(brand) {
            let b = getBrandInfo(brand);
            return b ? b.name : brand;
        }
    },
    computed: {
        applicationName() {
            return "Beaver"
        }
    },
    methods: {
        success(message) {
            this.$buefy.toast.open({
                message: message,
                type: 'is-success'
            })
        },
        failure(error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                error = error.response.data.message;
            }
            this.$buefy.toast.open({
                message: error,
                type: 'is-danger'
            })
        },
        handleFormError(error) {
            if (error.response.status === 422) {
                if (error.response.data && error.response.data.errors) {
                    this.$refs.observer.setErrors(error.response.data.errors);
                }
            } else {

                this.failure(error);
            }
        },
        download(task) {
            return 'api/tasks/' + task.id + '/download';
        },
        downloadFull(task) {
            return 'api/tasks/' + task.id + '/download?suffix=full.csv';
        },
        taskCompleted(task) {
            return task.status === 'completed';
        },
        taskFailed(task) {
            return task.status === 'failed';
        },
        taskPending(task) {
            return task.status === 'pending';
        },
        taskRunning(task) {
            return task.status === 'running';
        },
        taskUploadPending(task) {
            return task.status === 'upload-pending';
        },
        taskUploading(task) {
            return task.status === 'uploading';
        },
        taskUploaded(task) {
            return task.status === 'uploaded';
        },
        trimString(value, size) {
            if (value.length > size) {
                return value.substr(0, size) + '...';
            } else {
                return value;
            }
        },
        formatValue(value) {
            if (value != null) {
                let num = parseFloat(value);
                if (!isNaN(num)) {
                    return num;
                }
            }
            return "";
        },
        formatCurrency(value) {
            if (value) {
                let num = parseFloat(value);
                if (!isNaN(num)) {
                    return "$" + num.toFixed(2);
                }
            }
            return "$0";
        },
        generateSummaryData(task) {
            let summaries = [];
            if (task && task.reportSummaries) {
                for (let i = 0; i < task.reportSummaries.length; i++) {
                    let summary = task.reportSummaries[i];
                    summaries[summary.type] = summary;
                }
            }
            if (summaries.stadium_goods_new_item &&
                summaries.stock_x_new_item &&
                summaries.goat_new_item &&
                summaries.all_item) {


                return [
                    {
                        platform: "Stadium Goods",
                        newItemCount: this.formatValue(summaries.stadium_goods_new_item.itemCount),
                        newItemAverageProfit: this.formatCurrency(summaries.stadium_goods_new_item.averageProfit),
                        allItemCount: this.formatValue(summaries.stadium_goods_all_item.itemCount),
                        allItemAverageProfit: this.formatCurrency(summaries.stadium_goods_all_item.averageProfit)
                    },
                    {
                        platform: "StockX",
                        newItemCount: this.formatValue(summaries.stock_x_new_item.itemCount),
                        newItemAverageProfit: this.formatCurrency(summaries.stock_x_new_item.averageProfit),
                        allItemCount: this.formatValue(summaries.stock_x_all_item.itemCount),
                        allItemAverageProfit: this.formatCurrency(summaries.stock_x_all_item.averageProfit)
                    },
                    {
                        platform: "GOAT",
                        newItemCount: this.formatValue(summaries.goat_new_item.itemCount),
                        newItemAverageProfit: this.formatCurrency(summaries.goat_new_item.averageProfit),
                        allItemCount: this.formatValue(summaries.goat_all_item.itemCount),
                        allItemAverageProfit: this.formatCurrency(summaries.goat_all_item.averageProfit)
                    },
                    {
                        platform: "All",
                        newItemCount: this.formatValue(summaries.new_item.itemCount),
                        newItemAverageProfit: this.formatCurrency(summaries.new_item.averageProfit),
                        allItemCount: this.formatValue(summaries.all_item.itemCount),
                        allItemAverageProfit: this.formatCurrency(summaries.all_item.averageProfit)
                    },
                ];
            } else {
                return [];
            }

        }
    }
}
export default BaseMixins
