import axiosInstance from "./AxiosInstance";
import TokenService from "@/services/TokenService";
import {AxiosRequestConfig} from "axios";
const setup = (store:any) => {
    axiosInstance.interceptors.request.use(
        (config: AxiosRequestConfig<any>) => {
            const token = TokenService.getLocalAccessToken();
            if (token && config) {
                if(!config.headers){
                    config.headers = {}
                }
                config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await axiosInstance.post("/auth/refreshToken", {
                            accessToken:TokenService.getLocalAccessToken(),
                            refreshToken: TokenService.getLocalRefreshToken(),
                        });
                        const data = rs.data;
                        store.dispatch('Auth/refreshToken', data);
                        TokenService.updateLocalAccessToken(data);
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};
export default setup;