import {Country} from "@/common/Country";

export class CountryInfo {
    private readonly _code: Country;
    private readonly _name: string;


    constructor(country: Country, name: string) {
        this._code = country;
        this._name = name;
    }


    get country(): Country {
        return this._code;
    }

    get name(): string {
        return this._name;
    }
}


const CountryInfos = [
    new CountryInfo(Country.US, 'United State'),
    new CountryInfo(Country.HK, 'Hong Kong'),
    new CountryInfo(Country.CA, 'Canada'),
    new CountryInfo(Country.JP, 'Japan'),
    new CountryInfo(Country.CN, 'China'),
    new CountryInfo(Country.GB, 'United Kingdom'),
]

export function getAllCountries(): CountryInfo[] {
    return CountryInfos;
}

export function getCountryInfo(Country: Country): CountryInfo | null {
    let infos: CountryInfo[] = CountryInfos.filter((CountryInfo => CountryInfo.country == Country));
    return infos.length > 0 ? infos[0] : null;
}