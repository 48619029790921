class TokenService {
    getLocalRefreshToken() {
        const user = TokenService._getUser();
        return user?.refreshToken;
    }

    getLocalAccessToken() {
        const user = TokenService._getUser();
        return user?.accessToken;
    }

    updateLocalAccessToken(token: any) {
        let user = TokenService._getUser();
        if (user) {
            user.accessToken = token.accessToken;
            user.refreshToken = token.refreshToken;
            if (localStorage.getItem('user') != null) {
                localStorage.setItem("user", JSON.stringify(user));
            } else if (sessionStorage.getItem('user') != null) {
                sessionStorage.setItem("user", JSON.stringify(user));
            }
        }
    }

    setUser(user: any, staySignedIn: boolean) {
        if (staySignedIn) {
            localStorage.setItem("user", JSON.stringify(user));
        } else {
            sessionStorage.setItem("user", JSON.stringify(user));
        }
    }

    getUser() {
        return TokenService._getUser();
    }

    removeUser() {
        localStorage.removeItem("user");
        sessionStorage.removeItem("user");
    }

    private static _getUser() {
        let storeUser = localStorage.getItem('user') || sessionStorage.getItem('user');
        if (storeUser) {
            return JSON.parse(storeUser);
        } else {
            return null;
        }
    }
}

export default new TokenService();