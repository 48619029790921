import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '@/components/Home.vue'
import Login from '@/components/Login.vue'
import Dashboard from '@/components/Dashboard.vue'

const InventoryChecker = () =>
    import(/* webpackChunkName: "inventory-checker" */ '@/components/inventory-checker/InventoryChecker.vue')
//
const ProcessChecker = () =>
    import(/* webpackChunkName: "process-checker" */ '@/components/process-checker/ProcessChecker.vue')
//
const GoatOfferUpdater = () =>
    import(/* webpackChunkName: "goat-offer-updater" */ '@/components/goat-offer-updater/GoatOfferUpdater.vue')
//
const Zozo = () =>
    import(/* webpackChunkName: "zozo" */ '@/components/zozo/Zozo.vue')
//
const Nike = () =>
    import(/* webpackChunkName: "nike" */ '@/components/nike/Nike.vue')
const CreateNike = () =>
    import(/* webpackChunkName: "nike" */ '@/components/nike/CreateNike.vue')
//
const SportingLife = () =>
    import(/* webpackChunkName: "sporting-life" */ '@/components/sporting-life/SportingLife.vue')
const CreateSportingLife = () =>
    import(/* webpackChunkName: "sporting-life" */ '@/components/sporting-life/CreateSportingLife.vue')
//
const NikeReport = () =>
    import(/* webpackChunkName: "nike-report" */ '@/components/nike-report/NikeReport.vue')
const CreateNikeReport = () =>
    import(/* webpackChunkName: "nike-report" */ '@/components/nike-report/CreateNikeReport.vue')
//
const SoleSense = () =>
    import(/* webpackChunkName: "sole-sense" */ '@/components/sole-sense/SoleSense.vue')
const CreateSoleSense = () =>
    import(/* webpackChunkName: "sole-sense" */ '@/components/sole-sense/CreateSoleSense.vue')
//
const ActiveSneaker = () =>
    import(/* webpackChunkName: "active-sneaker" */ '@/components/active-sneaker/ActiveSneaker.vue')
const CreateActiveSneaker = () =>
    import(/* webpackChunkName: "active-sneaker" */ '@/components/active-sneaker/CreateActiveSneaker.vue')
//
const CreateNikeHongKong = () =>
    import(/* webpackChunkName: "nike-hk" */ '@/components/nike-hk/CreateNikeHongKong.vue')
const NikeHongKong = () =>
    import(/* webpackChunkName: "nike-hk" */ '@/components/nike-hk/NikeHongKong.vue')
//
const StadiumGoodsPriceUpdater = () =>
    import(/* webpackChunkName: "sg-price-updater" */ '@/components/stadium-goods-price-updater/StadiumGoodsPriceUpdater.vue')
const StadiumGoodsPriceUpdaterInterval = () =>
    import(/* webpackChunkName: "sg-price-updater" */ '@/components/stadium-goods-price-updater/StadiumGoodsPriceUpdaterInterval.vue')
const StadiumGoodsPriceUpdaterRules = () =>
    import(/* webpackChunkName: "sg-price-updater" */ '@/components/stadium-goods-price-updater/StadiumGoodsPriceUpdaterRules.vue')
const CreateStadiumGoodsPriceUpdaterRule = () =>
    import(/* webpackChunkName: "sg-price-updater" */ '@/components/stadium-goods-price-updater/CreateStadiumGoodsPriceUpdaterRule.vue')
const EditStadiumGoodsPriceUpdaterRule = () =>
    import(/* webpackChunkName: "sg-price-updater" */ '@/components/stadium-goods-price-updater/EditStadiumGoodsPriceUpdaterRule.vue')
//
const ManageParameters = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/ManageParameters.vue')
const GlobalParameters = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/GlobalParameters.vue')
const CountryParameters = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/CountryParameters.vue')
const ShippingParameters = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/ShippingParameters.vue')
const GeneralParameters = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/GeneralParameters.vue')
const CreateGlobalParameter = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/CreateGlobalParameter.vue')
const EditGlobalParameter = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/EditGlobalParameter.vue')
const CreateCountryParameter = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/CreateCountryParameter.vue')
const EditCountryParameter = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/EditCountryParameter.vue')
const CreateShippingParameter = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/CreateShippingParameter.vue')
const EditShippingParameter = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/EditShippingParameter.vue')
const CreateGeneralParameter = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/CreateGeneralParameter.vue')
const EditGeneralParameter = () =>
    import(/* webpackChunkName: "parameter" */ '@/components/parameter/EditGeneralParameter.vue')
//
const GoatListingTool = () =>
    import(/* webpackChunkName: "goat-listing-tool" */ '@/components/goat-listing-tool/GoatListingTool.vue')
const MangeGoatListingTool = () =>
    import(/* webpackChunkName: "goat-listing-tool" */ '@/components/goat-listing-tool/MangeGoatListingTool.vue')
const GoatConsignedList = () =>
    import(/* webpackChunkName: "goat-listing-tool" */ '@/components/goat-listing-tool/GoatConsignedList.vue')
//
const GoatSalesReport = () =>
    import(/* webpackChunkName: "goat-sales-report" */ '@/components/goat-sales-report/GoatSalesReport.vue')
const CreateGoatSalesReport = () =>
    import(/* webpackChunkName: "goat-sales-report" */ '@/components/goat-sales-report/CreateGoatSalesReport.vue')
//
const GoatPriceUpdaterInterval = () =>
    import(/* webpackChunkName: "goat-price-updater" */ '@/components/goat-price-updater/GoatPriceUpdaterInterval.vue')
const GoatPriceUpdater = () =>
    import(/* webpackChunkName: "goat-price-updater" */ '@/components/goat-price-updater/GoatPriceUpdater.vue')
const GoatPriceUpdaterRules = () =>
    import(/* webpackChunkName: "goat-price-updater" */ '@/components/goat-price-updater/GoatPriceUpdaterRules.vue')
const CreateGoatPriceUpdaterRule = () =>
    import(/* webpackChunkName: "goat-price-updater" */ '@/components/goat-price-updater/CreateGoatPriceUpdaterRule.vue')
const EditGoatPriceUpdaterRule = () =>
    import(/* webpackChunkName: "goat-price-updater" */ '@/components/goat-price-updater/EditGoatPriceUpdaterRule.vue')
//
const GoatLastSoldPrices = () =>
    import(/* webpackChunkName: "goat-sales-report" */ '@/components/goat-last-sold-prices/GoatLastSoldPrices.vue')
const CreateGoatLastSoldPrices = () =>
    import(/* webpackChunkName: "goat-sales-report" */ '@/components/goat-last-sold-prices/CreateGoatLastSoldPrices.vue')
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/sole-sense',
        name: 'sole-sense',
        component: SoleSense,
    },
    {
        path: '/sole-sense/create',
        name: 'create-sole-sense',
        component: CreateSoleSense,
    },
    {
        path: '/stadium-goods-price-updater',
        name: 'stadium-goods-price-updater',
        component: StadiumGoodsPriceUpdater,
    },
    {
        path: '/stadium-goods-price-updater/interval',
        name: 'interval-stadium-goods-price-updater',
        component: StadiumGoodsPriceUpdaterInterval,
    },
    {
        path: '/stadium-goods-price-updater/rules',
        name: 'rules-stadium-goods-price-updater',
        component: StadiumGoodsPriceUpdaterRules,
    },
    {
        path: '/stadium-goods-price-updater/rules/create',
        name: 'create-rules-stadium-goods-price-updater',
        component: CreateStadiumGoodsPriceUpdaterRule,
    },
    {
        path: '/stadium-goods-price-updater/rules/:ruleId',
        name: 'edit-rules-stadium-goods-price-updater',
        component: EditStadiumGoodsPriceUpdaterRule,
        props(route) {
            const props = {...route.params}
            return props
        }
    },
    {
        path: '/inventory-checker',
        name: 'inventory-checker',
        component: InventoryChecker,
    },
    {
        path: '/goat-offer-updater',
        name: 'goat-offer-updater',
        component: GoatOfferUpdater,
    },
    {
        path: '/goat-listing-tool',
        component: MangeGoatListingTool,
        children: [
            {
                name: 'goat-listing-tool',
                path: '',
                component: GoatListingTool
            },
            {
                name: 'consigned-list-goat-listing-tool',
                path: 'consigned-list',
                component: GoatConsignedList
            }
        ]
    },
    {
        path: '/goat-sales-report',
        name: 'goat-sales-report',
        component: GoatSalesReport,
    },
    {
        path: '/goat-sales-report/create',
        name: 'create-goat-sales-report',
        component: CreateGoatSalesReport,
    },
    {
        path: '/process-checker',
        name: 'process-checker',
        component: ProcessChecker,
    },
    {
        path: '/parameters',
        component: ManageParameters,
        children: [
            {
                name: 'parameters',
                path: '',
                component: GlobalParameters
            },
            {
                name: 'country-parameters',
                path: 'country-parameters',
                component: CountryParameters
            },
            {
                name: 'shipping-parameters',
                path: 'shipping-parameters',
                component: ShippingParameters
            },
            {
                name: 'general-parameters',
                path: 'general-parameters',
                component: GeneralParameters
            }
        ]
    },
    {
        path: '/parameters/create',
        name: 'create-parameters',
        component: CreateGlobalParameter,
    },
    {
        path: '/parameters/:id',
        name: 'edit-parameters',
        component: EditGlobalParameter,
        props(route) {
            const props = {...route.params}
            return props
        }
    },
    {
        path: '/country-parameters/create',
        name: 'create-country-parameters',
        component: CreateCountryParameter,
    },
    {
        path: '/country-parameters/:id',
        name: 'edit-country-parameters',
        component: EditCountryParameter,
        props(route) {
            const props = {...route.params}
            return props
        }
    },
    {
        path: '/shipping-parameters/create',
        name: 'create-shipping-parameters',
        component: CreateShippingParameter,
    },

    {
        path: '/shipping-parameters/:id',
        name: 'edit-shipping-parameters',
        component: EditShippingParameter,
        props(route) {
            const props = {...route.params}
            return props
        }
    },
    {
        path: '/general-parameters/create',
        name: 'create-general-parameters',
        component: CreateGeneralParameter,
    },

    {
        path: '/general-parameters/:id',
        name: 'edit-general-parameters',
        component: EditGeneralParameter,
        props(route) {
            const props = {...route.params}
            return props
        }
    },
    {
        path: '/zozo',
        name: 'zozo',
        component: Zozo,
    },
    {
        path: '/nike-hk',
        name: 'nike-hk',
        component: NikeHongKong,
    },
    {
        path: '/nike-hk/create',
        name: 'create-nike-hk',
        component: CreateNikeHongKong,
    },
    {
        path: '/nike',
        name: 'nike',
        component: Nike,
    },
    {
        path: '/nike/create',
        name: 'create-nike',
        component: CreateNike,
    },
    {
        path: '/sporting-life',
        name: 'sporting-life',
        component: SportingLife,
    },
    {
        path: '/sporting-life/create',
        name: 'create-sporting-life',
        component: CreateSportingLife,
    },
    {
        path: '/active-sneaker',
        name: 'active-sneaker',
        component: ActiveSneaker,
    },
    {
        path: '/active-sneaker/create',
        name: 'create-active-sneaker',
        component: CreateActiveSneaker,
    },
    {
        path: '/nike-report',
        name: 'nike-report',
        component: NikeReport,
    },
    {
        path: '/nike-report/create',
        name: 'create-nike-report',
        component: CreateNikeReport,
    },
    {
        path: '/goat-price-updater',
        name: 'goat-price-updater',
        component: GoatPriceUpdater,
    },
    {
        path: '/goat-price-updater/interval',
        name: 'interval-goat-price-updater',
        component: GoatPriceUpdaterInterval,
    },
    {
        path: '/goat-price-updater/rules',
        name: 'rules-goat-price-updater',
        component: GoatPriceUpdaterRules,
    },
    {
        path: '/goat-price-updater/rules/create',
        name: 'create-rules-goat-price-updater',
        component: CreateGoatPriceUpdaterRule,
    },
    {
        path: '/goat-price-updater/rules/:ruleId',
        name: 'edit-rules-goat-price-updater',
        component: EditGoatPriceUpdaterRule,
        props(route) {
            const props = {...route.params}
            return props
        }
    },
    {
        path: '/goat-last-sold-prices',
        name: 'goat-last-sold-prices',
        component: GoatLastSoldPrices,
    },
    {
        path: '/goat-last-sold-prices/create',
        name: 'create-goat-last-sold-prices',
        component: CreateGoatLastSoldPrices,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user') || sessionStorage.getItem('user');
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});
export default router
