import {AxiosPromise, AxiosRequestConfig} from "axios";
import axiosInstance from "@/services/AxiosInstance";


export class HttpService {


    /**
     *
     * @param url
     * @param params
     * @param config
     */
    protected post(url: string, params?: any, config?: AxiosRequestConfig): AxiosPromise<any> {
        return axiosInstance.post(url, params, config);
    }

    /**
     *
     * @param url
     * @param config
     */
    protected get(url: string, config?: AxiosRequestConfig): AxiosPromise<any> {
        return axiosInstance.get(url, config);
    }

    /**
     *
     * @param url
     * @param data
     * @param config
     */
    protected put(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<any> {
        return axiosInstance.put(url, data, config);
    }

    /**
     *
     * @param url
     * @param config
     */
    protected delete(url: string, config?: AxiosRequestConfig): AxiosPromise<any> {
        return axiosInstance.delete(url, config);
    }

    /**
     *
     * @param url
     * @param data
     * @param config
     * @protected
     */
    protected patch(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<any> {
        return axiosInstance.patch(url, data, config);
    }
}
