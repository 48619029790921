<template>
    <div class="container">
        <div class="columns is-centered">
            <div class="column is-8-tablet is-8-desktop is-8-widescreen">
                <b-loading :is-full-page="false" :active.sync="loading"></b-loading>

                <div class="panel is-primary">
                    <div class="columns is-vcentered">
                        <div class="column is-4 panel-heading">
                            <div class="has-text-centered p-2">
                                <img
                                    src="images/beaver-logo.png"
                                    alt="Beaver"
                                    style="width: auto"
                                />
                                <div class="is-size-4">Log Into {{ applicationName }}</div>
                            </div>
                        </div>
                        <div class="column is-8">
                            <div class="panel-block p-4">
                                <p class="control">
                                    <validation-observer ref="observer" v-slot="{ passes }">

                                <form v-on:submit.prevent="passes(onSubmit)">
                                    <div class="field">
                                        <div class="content">
                                            <b-input-with-validation rules="required|max:255"
                                                                     type="text"
                                                                     vid="email"
                                                                     label="Email"
                                                                     placeholder="Email"
                                                                     v-model="email"
                                            ></b-input-with-validation>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="content">
                                            <b-input-with-validation rules="required|max:255"
                                                                     type="password"
                                                                     vid="password"
                                                                     label="Password"
                                                                     placeholder="Password"
                                                                     password-reveal
                                                                     v-model="password"
                                            ></b-input-with-validation>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <b-checkbox v-model="staySignedIn">
                                            Remember Me
                                        </b-checkbox>
                                    </div>
                                    <div class="field">
                                        <div class="buttons  is-right">
                                            <button class="button is-success" type="submit">
                                                Log In
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                </validation-observer>

                                </p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import BInputWithValidation from "./BInputWithValidation";
import BaseMixins from "@/mixins/BaseMixins";
import {mapActions} from "vuex";

export default {
    name: "Login",
    components: {ValidationObserver, BInputWithValidation},
    mixins: [BaseMixins],
    data() {
        return {
            loading: false,
            email: "",
            password: "",
            staySignedIn: false
        }
    },
    methods: {
        ...mapActions("Auth", ['login']),
        onSubmit() {
            this.loading = true;
            let data = {
                email: this.email.trim(),
                password: this.password.trim(),
                staySignedIn: this.staySignedIn
            }
            this.login(data).then(() => {
                this.loading = false;
                this.$router.push("/");
            }).catch((error) => {
                if (error.response.statusCode === 422) {
                    if (error.response.data && error.response.data.errors) {
                        this.$refs.observer.setErrors(error.response.data.errors);
                    }
                } else {
                    if (error.response.data && error.response.data.message) {
                        this.failure(error.response.data.message);
                    } else {
                        this.failure(error);
                    }
                }
                this.loading = false;
            })
        }
    }
}
</script>

